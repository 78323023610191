@charset "UTF-8";
@import '_ress.scss';

:root {
  --color-grey: #E7E7E5;
  --color-white: #ffffff;
  --color-dark: #111111;
  --color-red: #DE0117;
  --color-blue: #0136A6;

  --background: var(--color-white);
  --text: #0b0b0b;
  --read-more-dots: #2259f0;
}

svg:not(:root) {
  overflow: hidden;
  /* Internet Explorer 11- */
}

.vh {
  position: absolute;
  z-index: -2;
  width:0.1px;
  height:0.1px;
  opacity: 0;
}

/* # =================================================================
   # Selection
   # ================================================================= */
/* Specify text selection background color and omit drop shadow */
::-moz-selection {
  /* Required when declaring ::selection */
  background-color: rgba(var(--background), 0.99);
  color: rgba(var(--color-white), 0.99);
  text-shadow: none;
}

::selection {
  /* Required when declaring ::selection */
  background-color: rgba(var(--background), 0.99);
  color: rgba(var(--color-white), 0.99);
  text-shadow: none;
}

.btn {
  outline: none;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  transition: all, 0.2s;
}
html,
body {
  overflow: hidden;
}

body {
  // font-family: 'Dosis', sans-serif;
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: normal;
  font-size: calc(24px + 1vw);
  line-height: 1.5;
  letter-spacing: 5px;
  color: var(--text);
  background: var(--background);
  cursor: default;
  cursor: url("/images/cursor.png") 10 10, default;
}

.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper {
  width: 90vw;
  max-width: 48ch;
  padding-top: calc(40px + 2vw);
  padding-left: calc(20px + 2vw);
  z-index: 10;
  img {
    margin-right: 10px;
  }
}

[data-hide] {
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  position: absolute;
  opacity: 0;
  color: var(--color-blue);
}

a,
button {
  color: var(--read-more-dots);
  cursor: pointer;
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    display: none;
    width: 100px;
    height: 14px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-3deg);
    z-index: -1;
    background-color: var(--color-blue);
  }
  &[title] {
    position: relative;
    &:after {
      position: absolute;
      left: 50%; bottom: 0;
      transform: translate(-50%, 100%);
      content: attr(title);
      color: var(--color-white);
      background: var(--color-dark);
      font-size: 14px;
      padding: 6px 12px;
      letter-spacing: 1px;
      white-space: nowrap;
      display: none;
      opacity: 0;
      outline: none;
    }
    &:hover, &:focus {
      &:after {
        display: block;
        animation: appearhover .44s ease .1s forwards;
      }
    }
  }
  &:hover,
  &:focus {
    cursor: url("/images/cursor-h.png") 5 5, default;
    &:before {
      display: block;
    }
  }
}

.full {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  font-size: calc(100px + 2vw);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  background-color: var(--background);
  animation: appearhover 0.17s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

@keyframes appearhover {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

p {
  margin: calc(20px + 1vw) 0;
}
